import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import '../../styles/Home.css';

function Home() {
    const [username, setUsername] = useState('');
    const [clientName, setClientName] = useState('');
    const [dashboards, setDashboards] = useState([]);  // Armazena os dashboards
    const [fadeOut, setFadeOut] = useState(false);  // Estado para controlar a transição
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            try {
                const decodedToken = jwtDecode(token);
                setUsername(decodedToken.username);

                // Captura o client_id do token
                const clientId = decodedToken.clientes_id;

                // Headers de autenticação
                const apiKey = process.env.REACT_APP_API_KEY;

                // Faz a chamada para a API de clientes com os headers de autenticação
                fetch(process.env.REACT_APP_CLIENT_API, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Basic ${apiKey}`
                    }
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        const client = data.data.find((client) => client.id === clientId);
                        if (client) {
                            setClientName(client.nome);
                        } else {
                            setClientName('Cliente não encontrado');
                        }
                    } else {
                        console.error('Erro ao buscar clientes:', data);
                        setClientName('Erro ao buscar cliente');
                    }
                })
                .catch((error) => {
                    console.error('Erro na chamada da API de clientes:', error);
                    setClientName('Erro ao buscar cliente');
                });

                // Chama a API de dashboards e filtra pelo clientes_id
                fetch(process.env.REACT_APP_DASHBOARD_API, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Basic ${apiKey}`
                    }
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        const filteredDashboards = data.data.filter(dashboard => dashboard.clientes_id === clientId);
                        setDashboards(filteredDashboards);
                    } else {
                        console.error('Erro ao buscar dashboards:', data);
                    }
                })
                .catch((error) => {
                    console.error('Erro na chamada da API de dashboards:', error);
                });

                const currentTime = Date.now() / 1000;
                if (decodedToken.expires < currentTime) {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Erro ao decodificar o token JWT:', error);
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }, [navigate]);

    const handleDashboardClick = async (dashboard) => {
        const response = await fetch(process.env.REACT_APP_IP_API);
        const data = await response.json();
        const userIp = data.ip;

        const clickTime = new Date().toLocaleString();
        console.log(`Dashboard: ${dashboard.titulo}, IP: ${userIp}, Click Time: ${clickTime}`);

        setFadeOut(true);

        setTimeout(() => {
            navigate(`/bi-detail`, { state: { dashboardTitle: dashboard.titulo, dashboardUrl: dashboard.url } });
        }, 200);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div className={`home-container ${fadeOut ? 'fade-out' : 'fade-in'}`}>
            <div className="welcome-card">
                <h1>Welcome, {username}!</h1>
                <p>Group Name: {clientName}</p>
                <button className="logout-btn" onClick={handleLogout}>Logout</button>
            </div>

            <div className="dashboard-carousel">
                {dashboards.map((dashboard) => (
                    <div key={dashboard.id} className="dashboard-card" onClick={() => handleDashboardClick(dashboard)}>
                        {/* Exibe o ícone dinâmico e mantém o tamanho definido no CSS */}
                        <i className={`${dashboard.icone || 'fas fa-chart-bar'} dashboard-icon`}></i>
                        <h3>{dashboard.titulo}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Home;
