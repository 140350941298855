import React, { useState } from 'react';
import '../../styles/LoginScreen.css';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Popup from '../../components/Popup/Popup';

function LoginScreen() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorPopup, setErrorPopup] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const loginUrl = `${process.env.REACT_APP_LOGIN_URL}${username}/${password}`;
            const authorizationHeader = `Basic ${process.env.REACT_APP_API_KEY}`;

            const response = await fetch(loginUrl, {
                method: 'GET',
                headers: {
                    'Authorization': authorizationHeader,
                },
            });

            const data = await response.json();

            if (response.ok && data.status === 'success') {
                const token = data.data;
                localStorage.setItem('token', token);
                console.log('Login bem-sucedido! Token JWT:', token);
                navigate('/home'); // Redireciona para a tela de Home após o login bem-sucedido
            } else {
                setErrorPopup(true);
            }
        } catch (error) {
            setErrorPopup(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && <Loading />}
            {errorPopup && <Popup message="Credential error" onClose={() => setErrorPopup(false)} />}

            <div className="login-container">
                <div className="login-header">
                    <img src="/logo.png" alt="Logo" className="logo" />
                    <h2 className="login-title">BI.ROCHAMAR</h2>
                </div>
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="form-group">
                        <div className="input-icon">
                            <i className="fas fa-user"></i>
                            <input 
                                type="text" 
                                placeholder="User"
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)} 
                                required 
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-icon">
                            <i className="fas fa-lock"></i>
                            <input 
                                type="password" 
                                placeholder="Password"
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                required 
                            />
                        </div>
                    </div>
                    <button type="submit" className="login-btn">Login</button>
                </form>
            </div>
        </div>
    );
}

export default LoginScreen;
