import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import '../../styles/BiDetail.css';
import { FaArrowLeft } from 'react-icons/fa';

function BiDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const dashboardTitle = location.state?.dashboardTitle || 'Dashboard';  // Captura o título do dashboard ou define como "Dashboard" por padrão
    const dashboardUrl = location.state?.dashboardUrl;  // Captura a URL do dashboard enviada pela Home

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                if (decodedToken.expires < currentTime) {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Erro ao decodificar o token:', error);
                navigate('/login');
            }
        }
    }, [navigate]);

    const handleBack = () => {
        navigate('/home');
    };

    return (
        <div className="bi-detail-container">
            <div className="bi-detail-header">
                <button className="back-button" onClick={handleBack}>
                    <FaArrowLeft className="back-icon" />
                </button>
                <h1>{dashboardTitle}</h1>  {/* Exibe o título do dashboard */}
            </div>

            <div className="bi-iframe-container">
                {/* Exibe o dashboard de acordo com a URL fornecida */}
                {dashboardUrl ? (
                    <iframe
                        src={dashboardUrl}
                        title={dashboardTitle}
                        className="bi-iframe"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <p>Dashboard URL não disponível.</p>
                )}
                <div className="bi-overlay"></div> {/* Overlay cobrindo a barra */}
            </div>
        </div>
    );
}

export default BiDetail;
