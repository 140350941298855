import React from 'react';
import '../../styles/Popup.css';

function Popup({ message, onClose }) {
    return (
        <div className="popup-container">
            <div className="popup">
                <p>{message}</p>
                <button onClick={onClose} className="close-btn">OK</button>
            </div>
        </div>
    );
}

export default Popup;
