import React from 'react';
import '../../styles/Loading.css';

function Loading() {
    return (
        <div className="loading-spinner">
            <div className="spinner"></div>
        </div>
    );
}

export default Loading;
