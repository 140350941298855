import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginScreen from './screens/Login/LoginScreen';
import Home from './screens/Home/Home';
import BiDetail from './screens/BiDetail/BiDetail';
import './styles/Colors.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
    useEffect(() => {
        if (!window._matomoLoaded) { // Verifica se o Matomo já foi carregado
            var _paq = window._paq = window._paq || [];
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
                var u = "//analytics.rochamar.com/";
                _paq.push(['setTrackerUrl', u + 'matomo.php']);
                _paq.push(['setSiteId', '14']);
                var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
                g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
            })();
            window._matomoLoaded = true; // Marca o Matomo como carregado
        }
    }, []);

    return (
        <Router>
            <noscript>
                <img referrerPolicy="no-referrer-when-downgrade" 
                     src="https://analytics.rochamar.com/matomo.php?idsite=14&rec=1" 
                     style={{border:0}} 
                     alt="" />
            </noscript>

            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/home" element={<Home />} />
                <Route path="/bi-detail" element={<BiDetail />} />
            </Routes>
        </Router>
    );
}

export default App;
